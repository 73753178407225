import React, { useState } from "react";
import { Modal } from "..";
import { IonSearchbar, IonList, IonRadioGroup, IonItem, IonLabel, IonRadio } from "@ionic/react";
import { ModalProps } from "../Modal/Modal";
import { Products_API } from "../../helpers/api";
import { ChoosenProduct } from "../OfferForm/offerCalculation";
export interface Props {
  modal: Omit<ModalProps, "children">;
  choosenProduct: ChoosenProduct | null;
  onSetChoosenProduct: (choosenProduct: ChoosenProduct) => void;
  allProducts: Products_API | null;
}
const ModalSelectProduct: React.FC<Props> = props => {
  const {
    isOpen,
    onClose,
    title
  } = props.modal;
  const {
    choosenProduct,
    onSetChoosenProduct,
    allProducts
  } = props;
  const [SearchText, setSearchText] = useState("");
  return <>
      <Modal isOpen={isOpen} onClose={onClose} title={title} {...props.modal}>
        <IonSearchbar value={SearchText} onIonChange={e => setSearchText(e.detail.value!)} />
        <IonList>
          <IonRadioGroup value={choosenProduct} onIonChange={e => {
          onSetChoosenProduct(e.detail.value);
        }}>
            {allProducts && allProducts.products.filter(p => p.name.toLowerCase().includes(SearchText.toLowerCase())).map((product, i) => <IonItem key={i}>
                    <IonLabel key={i}>{product.name}</IonLabel>
                    <IonRadio slot="start" value={{
              id: Number(product.id_product),
              name: product.name,
              inputs: product.inputs,
              price: product.price,
              tax: product.tax,
              mounting_price: product.mounting_price,
              limits: product.limits,
              product_type: product.product_type
            }} />
                  </IonItem>)}
          </IonRadioGroup>
        </IonList>
      </Modal>
    </>;
};
export default ModalSelectProduct;