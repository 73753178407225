import React, { useState, useEffect } from "react";
import "./ResponsiveTable.css";
import { IonButton, IonItem, IonCol, IonPopover, IonLabel, IonList, IonRow, IonCheckbox, IonIcon, IonText, IonLoading } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { chatbubbleEllipsesOutline, chatbubbleOutline, chevronDown, chevronUp, ellipsisHorizontal, filterOutline, informationCircleOutline, warningOutline, swapVerticalOutline } from "ionicons/icons";
import moment from "moment";
import { startCase } from "lodash";
import { RowAction } from "../../models";
interface OptionalProps {
  hasNote: boolean;
  isChecked: boolean;
  id: string | number;
}
export interface Row<T extends OptionalProps> {
  [k: string]: T;
}
interface Header {
  key: string;
  header: string;
}
interface Option extends Header {
  isSelected?: boolean;
}
interface TableProps<T extends OptionalProps> {
  tableName?: string;
  columns: Header[];
  rows: Row<T & OptionalProps>[];
  rowActions?: RowAction<T>[];
  stripped?: boolean;
  hideColumnByKeys?: string[];
  onClickRow: (row: Row<any>) => void;
  accordion?: boolean;
  onClickShowNotes: (row: Row<any>) => void;
  loading?: boolean;
  onSort?: (key: string) => void;
  onCollapse: (tableName: string) => void;
  collapse: boolean;
  onSetColumnFilters?: (keys: string[]) => void;
  columnFilters?: string[];
  onClickSecondaryActions?: (event: any) => void;
}
type Props<T = any & OptionalProps> = React.FC<TableProps<T & OptionalProps>>;
const renderColor = (key: "row_green" | "row_purple" | "row_yellow" | "row_red") => {
  switch (key) {
    case "row_green":
      return "#B6D7A8";
    case "row_purple":
      return "#B4A7D6";
    case "row_yellow":
      return "#FFE599";
    case "row_red":
      return "#EA9999";
    default:
      break;
  }
};
const ResponsiveTableComponent: Props = props => {
  // Descontructs
  const {
    t
  } = useTranslation();
  const {
    rows,
    columns,
    rowActions,
    tableName,
    stripped,
    hideColumnByKeys,
    onClickRow,
    onClickShowNotes,
    loading,
    onSort,
    collapse,
    onCollapse,
    columnFilters,
    onSetColumnFilters,
    onClickSecondaryActions
  } = props;

  // States
  const primaryAction = rowActions?.find(r => r.primary);
  const secondaryActions = rowActions?.filter(r => !r.primary);
  const [columnsToShow, setColumnsToShow] = useState<Option[]>([]);
  const [filteredColumns, setFilteredColumns] = useState<string[]>([]);
  const [popover, setPopover] = useState<{
    event: Event | undefined;
    open: boolean;
    row: any;
  }>({
    event: undefined,
    open: false,
    row: null
  });
  const [columnsPopover, setColumnsPopover] = useState<{
    event: Event | undefined;
    open: boolean;
  }>({
    event: undefined,
    open: false
  });
  const [isMounted, setIsMounted] = useState(false);

  // Initialization
  useEffect(() => {
    setIsMounted(true);
    if (isMounted) {
      const filtered: string[] = [];
      const columnsFilter: Option[] = columns.map((c: Option) => {
        if (columnFilters && columnFilters.length === 0) {
          if (!hideColumnByKeys?.includes(c.key)) {
            c.isSelected = true;
            filtered.push(c.key);
            return c;
          }
        } else if (!hideColumnByKeys?.includes(c.key) && columnFilters?.includes(c.key)) {
          c.isSelected = true;
          filtered.push(c.key);
          return c;
        }
        c.isSelected = false;
        return c;
      });
      setFilteredColumns(filtered);
      setColumnsToShow(columnsFilter);
      setPopover({
        event: undefined,
        open: false,
        row: null
      });
    }
    return () => {
      setPopover({
        event: undefined,
        open: false,
        row: null
      });
      setColumnsPopover({
        event: undefined,
        open: false
      });
      setColumnsToShow([]);
      setIsMounted(false);
    };
  }, [columnFilters, columns, hideColumnByKeys, isMounted]);

  // Render Functions
  const renderTableHeader = <thead>
      <tr className={"theader"}>
        {columns.map((c, i) => filteredColumns.includes(c.key) && <th onClick={() => {
        if (onSort) {
          return onSort(c.key);
        }
      }} key={c.key + i}>
                {onSort && c.key === "AssignedGB" && <IonIcon icon={swapVerticalOutline} />}{" "}
                {t(c.header)}
              </th>)}
        {rowActions && <th className={"actions"}>{t("Actions")}</th>}
      </tr>
    </thead>;
  const renderPopover = <IonPopover isOpen={popover.open} event={popover.event}
  // backdropDismiss={false}
  onDidPresent={() => popoverCleanup()} onWillDismiss={e => {
    //TODO problem
    if (isMounted) {
      e.stopPropagation();
      e.preventDefault();
      setPopover({
        open: false,
        event: undefined,
        row: null
      });
      return false;
    }
  }}>
      <IonList>
        {secondaryActions && secondaryActions.length > 0 && secondaryActions.map((action, i) => {
        return <IonItem disabled={action.disabled} key={i} button onClick={e => {
          e.preventDefault();
          setPopover({
            open: false,
            event: undefined,
            row: null
          });
          action.fn(e, popover.row);
        }}>
                <IonLabel>{action.name}</IonLabel>
              </IonItem>;
      })}
      </IonList>
    </IonPopover>;
  const renderColumnOptions = <IonPopover isOpen={columnsPopover.open} event={columnsPopover.event} animated={false} onWillDismiss={() => {
    onSetColumnFilters && onSetColumnFilters(filteredColumns);
    setColumnsPopover({
      open: false,
      event: undefined
    });
  }}>
      {columnsToShow && columnsToShow.map((header: Option) => {
      if (!hideColumnByKeys?.find(k => k === header.key)) {
        return <IonItem key={header.key}>
                <IonLabel>{header.header}</IonLabel>
                <IonCheckbox slot="start" checked={header.isSelected} value={header.key} onIonChange={e => handleColumnFilter({
            key: e.detail.value,
            isSelected: e.detail.checked,
            header: startCase(e.detail.value)
          })} color="primary" />
              </IonItem>;
      }
      return null;
    })}
    </IonPopover>;
  const [popoverState, setpopoverState] = useState<{
    open: boolean;
    message: string;
    event: Event | undefined;
  }>({
    open: false,
    message: "",
    event: undefined
  });
  const tooltip = <IonPopover event={popoverState.event} isOpen={popoverState.open} onDidDismiss={() => setpopoverState({
    open: false,
    message: "",
    event: undefined
  })}>
      <p>{popoverState.message}</p>
    </IonPopover>;

  // Helper Functions
  const parseValues = (value: any, row?: Row<any>) => {
    const _val = value instanceof Date || value! instanceof Object ? moment(value).format("DD-MM-YY") : value;
    if (typeof value === "boolean") {
      return _val ? <IonButton fill={"clear"} onClick={e => {
        e.preventDefault();
        onClickShowNotes(row ? row : {});
      }}>
          <IonIcon icon={chatbubbleEllipsesOutline} />
        </IonButton> : <IonButton onClick={_e => {
        _e.preventDefault();
        setpopoverState({
          event: ((_e as unknown) as Event),
          message: "No notes for this order!",
          open: true
        });
      }} fill="clear">
          <IonIcon color="medium" icon={chatbubbleOutline} />
        </IonButton>;
    }
    return isNaN(_val) ? String(_val) : String(_val);
  };
  const handleColumnFilter = (opt: Option) => {
    const colcopy = [...columnsToShow];
    const colIndex = columnsToShow.findIndex(c => c.key === opt.key);
    colcopy[colIndex] = opt;
    setColumnsToShow(colcopy);
    setFilteredColumns(opt.isSelected ? [...filteredColumns, opt.key] : filteredColumns.filter(f => f !== opt.key));
  };
  const popoverCleanup = () => {
    const popover = document.querySelector(".popover-content");
    popover && popover.addEventListener("click", e => {
      // setPopover({ open: false, event: undefined, row: null });
    });
  };
  // const [Collapse, setCollapse] = useState(true);
  return <>
      {renderColumnOptions}
      {renderPopover}
      {tooltip}
      {!loading ? <>
          <IonRow className={"ion-align-items-center"}>
            <IonCol style={{
          display: "flex",
          cursor: "pointer"
        }} className={"ion-align-items-center"} onClick={() => onCollapse(tableName ?? "")}>
              <IonButton size="small" fill="clear">
                <IonIcon icon={collapse ? chevronDown : chevronUp} />
              </IonButton>
              {tableName && <h2 className={"tablename"}>
                  {tableName} ({rows.length})
                </h2>}
            </IonCol>
            <IonCol>
              <IonButton onClick={e => setColumnsPopover({
            open: true,
            event: e.nativeEvent
          })} className={"ion-float-right"} fill={"clear"}>
                <IonIcon icon={filterOutline} /> {t("Columns")}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className={collapse ? "ion-hide" : undefined} style={{
        overflow: "auto"
      }}>
            <table className={"table"}>
              {renderTableHeader}
              <tbody>
                {rows.map((r, i: number) => <tr style={{
              backgroundColor: renderColor(r.background_color)
            }} key={i} className={stripped && i % 2 !== 0 ? "stripped" : undefined}>
                    {Object.values(r).map((v, i) => filteredColumns.includes(Object.keys(r)[i]) && <td onClick={() => Object.keys(r)[i] !== "Note" && onClickRow(r)} key={i}>
                            {/* {r.TimePendingInDays && r.warningLevel} */}
                            {(Object.keys(r)[i] === "TimePendingInDays" || Object.keys(r)[i] === "TimeSentInDays" || Object.keys(r)[i] === "TimeWaitingInDays") && (r.warningLevel === "danger" || r.warningLevel === "warning") ? <span style={{
                  display: "flex",
                  alignItems: "center"
                }}>
                                <IonIcon color={r.warningLevel === "warning" ? "warning" : "danger"} icon={r.warningLevel === "warning" ? informationCircleOutline : warningOutline} />
                                <IonText color={r.warningLevel === "warning" ? "warning" : "danger"}>
                                  {parseValues(v, r)}
                                </IonText>
                              </span> : parseValues(v, r)}
                          </td>)}
                    {rowActions && <td key={`actions-${i}`} className={"actions"}>
                        <table className={"no-style"}>
                          <tbody>
                            <tr>
                              {primaryAction && <td>
                                  <IonButton disabled={primaryAction.disabled} key={primaryAction.name} fill={"clear"} onClick={(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => primaryAction.fn(e, r)}>
                                    {primaryAction.icon && <IonIcon icon={primaryAction.icon} />}
                                    {primaryAction.name}
                                  </IonButton>
                                </td>}
                              {secondaryActions && secondaryActions.length > 0 && <td>
                                  <IonButton fill="clear" onClick={e => {
                          // onClickSecondaryActions(r);
                          isMounted && setPopover({
                            event: e.nativeEvent,
                            open: true,
                            row: r
                          });
                        }}>
                                    <IonIcon icon={ellipsisHorizontal} />
                                  </IonButton>
                                </td>}
                            </tr>
                          </tbody>
                        </table>
                      </td>}
                  </tr>)}
              </tbody>
            </table>
          </IonRow>
        </> : <IonLoading isOpen={loading} />}
    </>;
};
const ResponsiveTable = React.memo(ResponsiveTableComponent);
export default ResponsiveTable;