import React, { useState, useEffect } from "react";
import { RootState } from "typesafe-actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { IonContent, IonRefresher, IonRefresherContent, IonSearchbar, isPlatform } from "@ionic/react";
import { getAPILeads, getMounters, sortLead, getAPILeadsByTimestamp, openTabs, selectedFilters } from "./redux/actions";
import { storageGet } from "../../helpers/";
import { BasicOrder, Measurring, ModalAction, NewLeads, RowAction } from "../../models/";
import { addCircleOutline, addOutline, bookmarkOutline, chevronDownCircleOutline, gitCompareOutline, refreshOutline, sendOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { getGBSAction } from "../Details/redux/actions";
import { RefresherEventDetail } from "@ionic/core";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import RefreshButtons from "./components/RefreshButtons";
import Tables from "./components/Tables";
import { filterArray } from "./helper";
import Modals, { ModalsProps } from "./components/Modals";
import { ModalState, NotesState, LogActionState, SmsModalState, ResendAlertState } from "./types";
const Loading = React.lazy(() => import("../../components/Loading"));
const LeadsFilter = React.lazy(() => import("./components/LeadsFilter"));
const LeadsFeatureComponent: React.FC<{} & RouteComponentProps> = ({
  history
}) => {
  /*Hooks*/
  const dispatch = useDispatch();
  const {
    t
  } = useTranslation();

  /*Selectors*/
  const isLoading = useSelector((state: RootState) => state.leadsReducer.isLoading);
  const error = useSelector((state: RootState) => state.leadsReducer.error);
  const leads = useSelector((state: RootState) => state.leadsReducer.leads, isEqual);
  const role = useSelector((state: RootState) => state.authReducer.role);
  const userID = useSelector((state: RootState) => state.authReducer.userID);
  const token = useSelector((state: RootState) => state.authReducer.token);
  const userName = useSelector((state: RootState) => state.authReducer.name);
  const [searchText, setSearchText] = useState<string>("");
  const activeTabs = useSelector((state: RootState) => state.leadsReducer.openTabs);
  const activeColumnFilters = useSelector((state: RootState) => state.leadsReducer.columnFilters);
  const gbs = useSelector((state: RootState) => state.calendarsReducer.operator, shallowEqual);
  const workingOnline = useSelector((state: RootState) => state.authReducer.workingOnline);
  const measuringLeads = useSelector((state: RootState) => state.leadsReducer.measuringLeads.sort((a, b) => new Date(b.OrderDate).getTime() - new Date(a.OrderDate).getTime()));
  const newLeads = useSelector((state: RootState) => state.leadsReducer.newLeads).sort((a, b) => new Date(b.OrderDate).getTime() - new Date(a.OrderDate).getTime());
  const finishedLeads = useSelector((state: RootState) => state.leadsReducer.finishedLeads).sort((a, b) => new Date(b.OrderDate).getTime() - new Date(a.OrderDate).getTime());
  const offerPendingLeads = useSelector((state: RootState) => state.leadsReducer.offerPendingLeads);
  const deliveryPendingLeads = useSelector((state: RootState) => state.leadsReducer.deliveryPendingLeads).sort((a, b) => new Date(b.OrderDate).getTime() - new Date(a.OrderDate).getTime());
  const offerSentLeads = useSelector((state: RootState) => state.leadsReducer.offerSentLeads);
  const paymentWaitingLeads = useSelector((state: RootState) => state.leadsReducer.paymentWaitingLeads);
  const mountingPendingLeads = useSelector((state: RootState) => state.leadsReducer.mountingPendingLeads).sort((a, b) => new Date(b.OrderDate).getTime() - new Date(a.OrderDate).getTime());

  /*State*/
  const [ModalStatus, setModalStatus] = useState<ModalState>({
    open: false
  });
  const [RowID, setRowID] = useState(0);
  const [NotesModal, setNotesModal] = useState<NotesState>({
    bol: false,
    notes: []
  });
  const [openLogAction, setLogAction] = useState<LogActionState>({
    open: false,
    row: undefined
  });
  const [isReschedule, setIsReschedule] = useState(false);
  const [openUpdateAssignedGB, setOpenUpdateAssignedGB] = useState({
    open: false,
    order_id: 0
  });
  const [smsModal, setSMSModal] = useState<SmsModalState>({
    isOpen: false,
    id_order: 0
  });
  const [resendAlertStatus, setResendAlertStatus] = useState<ResendAlertState>({
    id_order: -1,
    open: false
  });
  const [MounterModal, setMounterModal] = useState(false);
  const [HasMeetingAlready, setHasMeetingAlready] = useState(false);
  /*Constants*/
  const filteredNames = Object.values(gbs).filter(gb => gb.isSelected).map(gb => gb.id);
  const changePage = (id: string | number) => {
    history.push(`/leads/${id}`);
  };
  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(getAPILeads.request({
      userID: userID!,
      role,
      token: token!,
      name: userName!
    }));
    !isLoading && event.detail.complete();
  };

  /*Actions */
  const assignAction: RowAction<BasicOrder> = {
    name: t("Assign"),
    fn: (e, r) => {
      dispatch(getMounters.request({
        token: token!
      }));
      setRowID(r.OrderID);
      setMounterModal(true);
    },
    primary: true,
    icon: bookmarkOutline
  };
  const logCallAction: RowAction<BasicOrder> = {
    name: t("LogCall"),
    fn: (e, r) => {
      setLogAction({
        open: true,
        row: r
      });
    }
  };
  const scheduleAction: RowAction<BasicOrder> = {
    name: t("Schedule"),
    fn: (e, r) => {
      e.preventDefault();
      changePage(r.OrderID);
    },
    primary: true,
    icon: gitCompareOutline
  };
  const reScheduleAction: RowAction<BasicOrder> = {
    name: t("Re-Schedule"),
    disabled: !HasMeetingAlready,
    fn: (e, r) => {
      e.preventDefault();
      setIsReschedule(r.hasMeeting);
      setModalStatus({
        open: true,
        order_id: Number(r.OrderID)
      });
    },
    primary: true,
    icon: gitCompareOutline
  };
  const assignNewGB: RowAction<BasicOrder> = {
    name: t("Assign a new GB"),
    fn: (e, r) => {
      e.preventDefault();
      setOpenUpdateAssignedGB({
        open: true,
        order_id: Number(r.OrderID)
      });
    },
    primary: false
  };
  const addNewEventAction: RowAction<BasicOrder> = {
    name: t("Add new meeting"),
    fn: (e, r) => {
      e.preventDefault();
      setIsReschedule(false);
      setModalStatus({
        open: true,
        order_id: Number(r.OrderID)
      });
    },
    primary: false,
    icon: addOutline
  };
  const sendSMSAction: RowAction<BasicOrder> = {
    name: t("Send SMS"),
    fn: (e, r) => {
      e.preventDefault();
      setSMSModal({
        isOpen: true,
        id_order: r.OrderID
      });
    },
    primary: false,
    icon: sendOutline
  };
  const createOfferAction: RowAction<BasicOrder> = {
    name: t("AddOffer"),
    fn: (e, r) => {
      e.preventDefault();
      history.push(`/leads/offer/${r.OrderID}/new`);
    },
    primary: true,
    icon: addCircleOutline
  };
  const resendOfferAction: RowAction<BasicOrder> = {
    name: t("ReSendOfferBtn"),
    fn: (e, r) => {
      e.preventDefault();
      setResendAlertStatus({
        open: true,
        id_order: r.OrderID
      });
    },
    primary: true,
    icon: refreshOutline
  };
  const createOfferActionSecondary: RowAction<BasicOrder> = {
    name: t("AddOffer"),
    fn: (e, r) => {
      e.preventDefault();
      history.push(`/leads/offer/${r.OrderID}/new`);
    }
  };
  const changeGBAction: RowAction<BasicOrder> = {
    name: t("ChangeGB"),
    fn: (e, r) => {
      e.preventDefault();
      changePage(r.OrderID);
    }
  };
  const showNotesAction: RowAction<BasicOrder> = {
    name: t("Show Notes"),
    fn: () => {
      // dispatch(getMounters.request({ token: token! }));
      // setRowID(r.OrderID);
      // setMounterModal(true);
    },
    primary: false
  };
  const renderMeasuringAction = () => {
    const mounterRoleActions = [scheduleAction, changeGBAction];
    const gbRoleActions = [reScheduleAction, sendSMSAction];
    return role === "Mounter" ? mounterRoleActions : gbRoleActions;
  };
  const closeModal: ModalAction = {
    name: "Close",
    fn: () => setNotesModal({
      bol: false,
      notes: []
    }),
    type: "button"
  };
  const leadsObj = {
    newLeads: {
      data: newLeads,
      rowActions: [scheduleAction, logCallAction, showNotesAction],
      rows: filterArray<NewLeads[]>(newLeads, searchText).filter(a => a.AssignedGB && filteredNames.length > 0 ? a.gbID && filteredNames.includes(a.gbID) : a),
      hideColumnByKeys: ["warningLevel", "notes", "background_color", "gbID"]
    },
    measuringLeads: {
      data: (measuringLeads as Measurring[]),
      rowActions: [...renderMeasuringAction(), assignNewGB],
      rows: filterArray<Measurring[]>(measuringLeads, searchText).filter(a => a.AssignedGB && filteredNames.length > 0 ? a.gbID && filteredNames.includes(a.gbID) : a),
      hideColumnByKeys: ["notes", "background_color", "gbID", "hasMeeting"]
    },
    offerPendingLeads: {
      data: offerPendingLeads,
      rowActions: [assignNewGB, createOfferAction, sendSMSAction, addNewEventAction, {
        ...reScheduleAction,
        primary: false
      }],
      onClickSecondaryActions: event => setHasMeetingAlready(event.hasMeeting),
      rows: filterArray(offerPendingLeads, searchText).sort((a, b) => a.TimePendingInDays - b.TimePendingInDays).filter(a => a.AssignedGB && filteredNames.length > 0 ? a.gbID && filteredNames.includes(a.gbID) : a),
      hideColumnByKeys: ["warningLevel", "notes", "background_color", "gbID", "hasMeeting"]
    },
    offerSentLeads: {
      data: offerSentLeads,
      rowActions: [resendOfferAction, createOfferActionSecondary],
      rows: filterArray(offerSentLeads, searchText).sort((a, b) => a.TimeSentInDays - b.TimeSentInDays).filter(a => a.AssignedGB && filteredNames.length > 0 ? a.gbID && filteredNames.includes(a.gbID) : a),
      hideColumnByKeys: ["warningLevel", "notes", "background_color", "gbID"]
    },
    paymentWaitingLeads: {
      data: paymentWaitingLeads,
      rowActions: [createOfferAction],
      rows: filterArray(paymentWaitingLeads, searchText).sort((a, b) => a.TimeWaitingInDays - b.TimeWaitingInDays).filter(a => a.AssignedGB && filteredNames.length > 0 ? a.gbID && filteredNames.includes(a.gbID) : a),
      hideColumnByKeys: ["warningLevel", "notes", "background_color", "gbID"]
    },
    mountingPendingLeads: {
      data: mountingPendingLeads,
      rowActions: [assignAction],
      rows: filterArray(mountingPendingLeads, searchText).filter(a => a.Mounter && filteredNames.length > 0 ? a.mounterID && filteredNames.includes(a.mounterID) : a),
      hideColumnByKeys: ["warningLevel", "notes", "background_color", "gbID", "mounterID"]
    },
    deliveryPendingLeads: {
      data: deliveryPendingLeads,
      rowActions: [],
      rows: filterArray(deliveryPendingLeads, searchText).filter(a => a.Mounter && filteredNames.length > 0 ? a.mounterID && filteredNames.includes(a.mounterID) : a),
      hideColumnByKeys: ["warningLevel", "notes", "background_color", "gbID", "mounterID"]
    },
    finishedLeads: {
      data: finishedLeads,
      rowActions: [],
      rows: filterArray<NewLeads[]>(finishedLeads, searchText).filter(a => a.AssignedGB && filteredNames.length > 0 ? a.gbID && filteredNames.includes(a.gbID) : a),
      hideColumnByKeys: ["warningLevel", "notes", "background_color", "gbID"]
    }
  };
  const modalObj: ModalsProps = {
    updateAssignedGB: {
      id_order: openUpdateAssignedGB.order_id ?? 0,
      onClose: () => {
        setOpenUpdateAssignedGB({
          open: false,
          order_id: 0
        });
      },
      open: openUpdateAssignedGB.open
    },
    smsModal: {
      id_order: smsModal.id_order,
      onClose: () => setSMSModal({
        isOpen: false,
        id_order: 0
      }),
      open: smsModal.isOpen
    },
    assignNotesModal: {
      actions: [closeModal],
      notes: NotesModal.notes,
      onClose: () => {
        setNotesModal({
          bol: false,
          notes: []
        });
      },
      open: NotesModal.bol
    },
    rescheduleModal: {
      isReschedule: isReschedule,
      onClose: () => setModalStatus({
        open: false,
        order_id: undefined
      }),
      open: ModalStatus.open,
      order_id: ModalStatus.order_id ?? 0
    },
    customerResponseModal: {
      onClose: () => setLogAction({
        open: false,
        row: undefined
      }),
      open: openLogAction.open
    },
    assingMounterModal: {
      onClose: () => {
        setModalStatus({
          open: false
        });
        setRowID(0);
        setMounterModal(false);
      },
      open: MounterModal,
      rowID: RowID
    },
    resendAlert: {
      id_order: resendAlertStatus.id_order,
      onClose: () => setResendAlertStatus({
        open: false,
        id_order: -1
      }),
      open: resendAlertStatus.open
    }
  };

  /*Use effect*/
  useEffect(() => {
    let isMounted = true;
    if (userID && role && token && userName && isMounted) {
      storageGet<Date>("timeStamp").then(timeStamp => {
        if (timeStamp && leads.length > 0) {
          return dispatch(getAPILeadsByTimestamp.request({
            user: {
              userID,
              role,
              token,
              name: userName
            },
            timeStamp: timeStamp
          }));
        } else {
          return dispatch(getAPILeads.request({
            userID,
            role,
            token,
            name: userName
          }));
        }
      }).catch(err => {
        console.error(err);
      });
      dispatch(getGBSAction.request({
        token: token!
      }));
    }
    return () => {
      isMounted = false;
      setSearchText("");
    };
  }, [dispatch, leads.length, role, token, userID, userName, workingOnline]);
  return <IonContent>
      <Modals {...modalObj} />
      <IonSearchbar value={searchText ? searchText : ""} onIonChange={e => setSearchText(e.detail.value!)} placeholder={t("SearchBy")} />
      <LeadsFilter gbs={gbs} role={role} />
      {error && <span>{error}</span>}
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent pullingIcon={chevronDownCircleOutline} pullingText={t("PullToRefresh")} refreshingSpinner="circles" refreshingText={t("Refreshing")}></IonRefresherContent>
      </IonRefresher>
      {isLoading ? <Loading loading={isLoading} onGoBack={() => history.goBack()} /> : leads.length > 0 ? <Tables activeColumnFilters={activeColumnFilters} onCollapse={tableName => {
      if (activeTabs.includes(tableName)) {
        return dispatch(openTabs(activeTabs.filter(tab => tab !== tableName)));
      }
      return dispatch(openTabs([...activeTabs, tableName]));
    }} activeTabs={activeTabs} leads={leadsObj} onClickRow={r => history.push(`/leads/${r.OrderID}`)} onClickShowNotes={r => setNotesModal({
      bol: true,
      notes: r.notes
    })} onSetColumnFilters={obj => {
      dispatch(selectedFilters(obj));
    }} onSort={key => dispatch(sortLead({
      key
    }))} isLoading={!!isLoading} /> : <h3>{t("NoLeads")}</h3>}
      {isPlatform("desktop") && <RefreshButtons onFullRefresh={() => dispatch(getAPILeads.request({
      userID: userID!,
      role,
      token: token!,
      name: userName!
    }))} onPartialRefresh={timeStamp => dispatch(getAPILeadsByTimestamp.request({
      user: {
        userID: userID!,
        role,
        token: token!,
        name: userName!
      },
      timeStamp: timeStamp ?? new Date()
    }))} />}
    </IonContent>;
};
const LeadsFeature = React.memo(LeadsFeatureComponent);
export default LeadsFeature;