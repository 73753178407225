import React, { useEffect, useState } from "react";
import "./Calendar.css";
import { Calendar, CalendarProps, momentLocalizer } from "react-big-calendar";
import withDragAndDrop, { withDragAndDropProps } from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { IonPopover, IonItem, IonLabel, IonCheckbox, IonButton, IonIcon, IonRow, IonCol, IonChip, IonLoading, IonSelect, IonSelectOption, IonDatetime, IonItemGroup, IonDatetimeButton, IonModal, IonInput } from "@ionic/react";
import { chevronDown, closeCircle, eyeOutline, navigateOutline, chatbubbleEllipsesOutline } from "ionicons/icons";
import { GB, GBArray, Meeting, OrderDetail, SlotInfo, SlotInfoEvent, ModalAction } from "../../models/";
import moment from "moment";
import { eventStyleGetter, isWeekday, renderEvent, validDate } from "../../helpers";
import { PopoverAction, Modal } from "..";
import { useTranslation } from "react-i18next";
import { IPopoverAction } from "../PopoverAction/PopoverAction";
import { isFunction } from "lodash";
import { updateAssignedGB } from "../../features/Leads/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { editEvent, initCalendars } from "../../features/Calendars/redux/actions";
export interface CalendarEvent {
  id?: string | number;
  title?: string | undefined;
  start?: Date | undefined;
  end?: Date | undefined;
  allDay?: boolean;
}
interface Filter {
  selectedFilter: (name: string) => void;
  deselectFilter: (id: number) => void;
  title: string;
}
interface IProps {
  isLoading: boolean;
  adminEvents?: Meeting[];
  drivingEvents?: CalendarEvent[];
  suggestedMeetings?: CalendarEvent[];
  gbs: GBArray;
  filter?: Filter;
  orderDetail?: OrderDetail;
  popoverEnabled?: boolean;
  onSelectNewEvent: (slotInfo: SlotInfo) => void;
  onEditEvent: (slotInfo: SlotInfoEvent) => void;
  onDeleteEvent: (meetingToDelete: Meeting) => void;
  onSeeDetails?: (meetingToDelete: Meeting) => void;
  onReschedule: (newStartDate: Date, newEndDate: Date, oldMeeting: Meeting, user_id: number) => void;
  onCreateEvent?: (start: Date, end: Date) => void;
  onError: (message: string) => void;
}
const DnDCalendar = withDragAndDrop((Calendar as any));
type CalendarWithoutLocalizar = Omit<CalendarProps, "localizer">;
const CalendarComponent: React.FC<CalendarWithoutLocalizar & withDragAndDropProps & IProps> = props => {
  // Variables
  const {
    gbs,
    drivingEvents,
    adminEvents,
    suggestedMeetings,
    filter,
    orderDetail,
    onSelectNewEvent,
    onCreateEvent,
    onEditEvent,
    onDeleteEvent,
    onSeeDetails,
    onReschedule,
    onError,
    isLoading = false
  } = props;
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.authReducer.token);
  const auth = useSelector((state: RootState) => state.authReducer);
  const gbArray = gbs ? Object.values(gbs) : [];
  const today = new Date();
  const {
    t
  } = useTranslation();
  // State
  const [filterPopover, setFilterPopover] = useState<{
    open: boolean;
    event: MouseEvent | undefined;
  }>({
    open: false,
    event: undefined
  });
  const [eventActionPopover, setEventActionPopover] = useState<{
    open: boolean;
    event: Event | undefined;
    calendarEvent: Meeting | undefined;
  }>({
    open: false,
    event: undefined,
    calendarEvent: undefined
  });
  const [changeDate, setChangeDate] = useState(false);
  const [assignNewGB, setAssignNewGB] = useState(false);
  const [NotesModal, setNotesModal] = useState<{
    bol: boolean;
    notes: {
      cfirstname: string | null;
      clastname: string | null;
      date_add: string | null;
      efirstname: string | null;
      elastname: string | null;
      id_customer: number;
      id_employee: number;
      id_message: number;
      message: string;
      private: number;
    }[];
  }>({
    bol: false,
    notes: []
  });
  // Cleanup
  useEffect(() => {
    return () => {
      cleanPopoverState();
    };
  }, []);
  // Methods
  const closeModal: ModalAction = {
    name: "Close",
    fn: () => setNotesModal({
      bol: false,
      notes: []
    }),
    type: "button"
  };
  const renderAssignNotesModal = <Modal title={t("Notes")} isOpen={NotesModal.bol} onClose={() => {
    setNotesModal({
      bol: false,
      notes: []
    });
  }} actions={[closeModal]}>
      {NotesModal.notes.map((note, i) => <>
          <IonItem key={note.message + i}>
            <IonLabel style={{
          paddingLeft: "0.5rem"
        }}>
              <h3>{note.message}</h3>
              <p>{note.date_add}</p>
            </IonLabel>
          </IonItem>
        </>)}
    </Modal>;
  const renderFilterPopover = filter && gbs ? <IonPopover isOpen={filterPopover.open} event={filterPopover.event} animated={false} onDidDismiss={() => setFilterPopover({
    open: false,
    event: undefined
  })}>
        <IonItemGroup>
          {gbArray.length > 0 && gbArray.map((o, i) => {
        return <IonItem key={i}>
                  <IonLabel>{o.name}</IonLabel>
                  <IonCheckbox slot="start" checked={o.isSelected ? o.isSelected : false} value={o.id.toString()} onIonChange={e => {
            o.isSelected ? props.filter?.deselectFilter(e.detail.value) : props.filter?.selectedFilter(e.detail.value);
            setFilterPopover({
              open: false,
              event: undefined
            });
          }} color="primary" />
                </IonItem>;
      })}
        </IonItemGroup>
      </IonPopover> : null;
  const renderFilterChips = filter && gbs && gbArray.filter(o => o.isSelected).length > 0 ? <IonRow className={"details-filter"}>
        <IonCol style={{
      display: "flex",
      alignItems: "center"
    }}>
          <IonLabel className={"details-label"}>
            {t("CurrentCalendarsFiltered")}
          </IonLabel>
          {gbArray.map((gb: GB) => gb.isSelected && <IonChip key={gb.id} style={{
        backgroundColor: gb.color
      }}>
                  <IonLabel>{gb.name}</IonLabel>
                  <IonIcon onClick={() => props.filter?.deselectFilter(gb.id)} icon={closeCircle} />
                </IonChip>)}
        </IonCol>
      </IonRow> : null;
  const renderTitle = gbArray.length < 1 ? t("NoOperators") : gbArray.find(o => o.isSelected) ? props.filter?.title : t("NoCalendarSelected");
  const cleanPopoverState = () => {
    setEventActionPopover({
      open: false,
      event: undefined,
      calendarEvent: undefined
    });
  };
  const popoverActions = () => {
    const actions: IPopoverAction[] = [{
      name: t("Delete"),
      fn: () => {
        onDeleteEvent(eventActionPopover.calendarEvent!);
        cleanPopoverState();
      }
    }, {
      name: t("Re-schedule"),
      fn: () => {
        setChangeDate(true);
      }
    }, {
      name: t("Assign new GB"),
      fn: () => {
        setAssignNewGB(true);
      }
    }];
    if (eventActionPopover.calendarEvent && onSeeDetails && eventActionPopover.calendarEvent.orderID) {
      const popoverSeeDetails: IPopoverAction = {
        name: t("OrderDetails"),
        fn: () => {
          onSeeDetails(eventActionPopover.calendarEvent!);
        }
      };
      actions.push(popoverSeeDetails);
    }
    if (eventActionPopover.calendarEvent?.title?.includes("Suggestion")) {
      const popoverSeeDetails: IPopoverAction = {
        name: t("CreateMeeting"),
        fn: () => {
          onCreateEvent && onCreateEvent(eventActionPopover.calendarEvent!.start!, eventActionPopover.calendarEvent!.end!);
        }
      };
      return [popoverSeeDetails];
    }
    return actions;
  };
  const renderEventPopover = <PopoverAction actions={popoverActions()} state={{
    open: eventActionPopover.open,
    event: eventActionPopover.event
  }} onClose={() => setEventActionPopover({
    open: false,
    event: undefined,
    calendarEvent: undefined
  })} />;
  const [eventTitle, setEventTitle] = useState(eventActionPopover.calendarEvent?.title ?? "");
  const [NewStartDate, setNewStartDate] = useState<string>(moment(eventActionPopover.calendarEvent?.start).format("YYYY-MM-DDTHH:mm"));
  const [NewEndDate, setNewEndDate] = useState<string>(moment(eventActionPopover.calendarEvent?.end).format("YYYY-MM-DDTHH:mm"));
  const [NewGB, setNewGB] = useState(eventActionPopover.calendarEvent?.operatorID);
  useEffect(() => {
    setEventTitle(eventActionPopover.calendarEvent?.title);
    setNewStartDate(moment(eventActionPopover.calendarEvent?.start).format("YYYY-MM-DDTHH:mm"));
    setNewEndDate(moment(eventActionPopover.calendarEvent?.end).format("YYYY-MM-DDTHH:mm"));
  }, [eventActionPopover.calendarEvent]);
  const renderChangeDateAlert = <Modal isOpen={changeDate} onClose={() => setChangeDate(false)} title={t("ChooseNewDate")} actions={[{
    fn: () => {
      setChangeDate(false);
      setEventActionPopover({
        calendarEvent: undefined,
        open: false,
        event: undefined
      });
    },
    name: "Cancel",
    type: "button",
    buttonStyle: "clear"
  }, {
    fn: () => {
      const startDate = moment(NewStartDate).toDate();
      const endDate = moment(NewEndDate).toDate();
      if (validDate(startDate, endDate)) {
        onReschedule(startDate, endDate, {
          ...eventActionPopover.calendarEvent,
          title: eventTitle
        }, Number(NewGB));
        cleanPopoverState();
      } else {
        onError(t("OnInvalidDateError"));
      }
      setChangeDate(false);
    },
    name: "Confirm",
    type: "button",
    buttonStyle: "solid"
  }]}>
      <IonItem>
        <IonLabel position={"floating"}>{t("Title")}</IonLabel>
        <IonInput name={"eventTitle"} type="text" placeholder={"PlaceholderEventTitle"} value={eventTitle} onIonChange={e => {
        setEventTitle(String(e.target.value));
      }} />
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Start Date</IonLabel>
        <IonDatetimeButton style={{
        marginTop: "1rem"
      }} datetime="datetimestart"></IonDatetimeButton>
        <IonModal keepContentsMounted={true}>
          <IonDatetime showDefaultButtons={true} id="datetimestart" onIonChange={e => {
          const newStartDateStr = (e.detail.value as string);
          if (newStartDateStr) {
            // Parse the new start date using moment in local time
            const newStartMoment = moment.parseZone(newStartDateStr);
            const currentStartMoment = moment.parseZone(NewStartDate);
            const currentEndMoment = moment.parseZone(NewEndDate);

            // Preserve the hours and minutes of the current start and end times
            const startHours = currentStartMoment.hours();
            const startMinutes = currentStartMoment.minutes();
            const endHours = currentEndMoment.hours();
            const endMinutes = currentEndMoment.minutes();

            // Update the new start moment with the preserved time
            newStartMoment.hours(startHours).minutes(startMinutes);

            // Create a new end moment with the preserved time
            const newEndMoment = newStartMoment.clone().hours(endHours).minutes(endMinutes);

            // Update the state with the formatted dates
            setNewStartDate(newStartMoment.format("YYYY-MM-DDTHH:mm"));
            setNewEndDate(newEndMoment.format("YYYY-MM-DDTHH:mm"));
          }
        }} minuteValues="0,15,30,45" isDateEnabled={isWeekday} hourCycle="h24" value={NewStartDate} min={moment().format("YYYY-MM-DDTHH:mm")} name="newStartDate" />
        </IonModal>
      </IonItem>
      <IonItem>
        <IonLabel position={"stacked"}>{t("EndRequired")}</IonLabel>
        <IonDatetimeButton style={{
        marginTop: "1rem"
      }} datetime="datetimeend"></IonDatetimeButton>
        <IonModal keepContentsMounted={true}>
          <IonDatetime showDefaultButtons={true} id="datetimeend" onIonChange={e => {
          setNewEndDate((e.detail.value as string) ?? "");
        }} minuteValues="0,15,30,45" hourValues={"8,9,10,11,12,13,14,15,16,17,18,19,20"} isDateEnabled={isWeekday} hourCycle="h24" value={NewEndDate} name="newEndDate" />
        </IonModal>
      </IonItem>
    </Modal>;
  const assignNewGBModal = <Modal isOpen={assignNewGB} onClose={() => setAssignNewGB(false)} title={"Assign New GB"} actions={[{
    fn: () => {
      let dateStart = new Date(eventActionPopover.calendarEvent.start);
      const newEvent = {
        ...eventActionPopover.calendarEvent,
        operatorID: NewGB
      };
      dispatch(editEvent.request({
        newStartDate: newEvent.start,
        newEndDate: newEvent.end,
        event: newEvent,
        token: token,
        id_user: Number(NewGB)
      }));
      dispatch(updateAssignedGB.request({
        token: token,
        id_order: Number(eventActionPopover.calendarEvent.orderID!),
        id_fitter: Number(NewGB),
        first_name: "",
        last_name: ""
      }));
      dispatch(initCalendars.request({
        userRole: auth.role,
        userID: auth.userID,
        calendarName: "",
        token: token,
        dateStart: new Date(dateStart.setDate(dateStart.getDate())),
        dateEnd: eventActionPopover.calendarEvent.end
      }));
      setAssignNewGB(false);
      setEventActionPopover({
        open: false,
        calendarEvent: undefined,
        event: undefined
      });
    },
    type: "button",
    name: "Save"
  }]}>
      <IonItem>
        <IonLabel position="stacked">GB</IonLabel>
        <IonSelect placeholder={"Choose GB"} key={"gb list"} value={NewGB} onIonChange={e => {
        setNewGB(e.detail.value);
      }}>
          {gbArray.map(gb => <IonSelectOption key={gb.id} value={gb.id}>
              {gb.name}
            </IonSelectOption>)}
        </IonSelect>
      </IonItem>
    </Modal>;
  // Component
  const CustomEvent = (event: any) => {
    const eventMeeting: Meeting = event.event;
    return <div style={{
      display: "flex",
      alignContent: "center",
      justifyContent: "space-between",
      height: "100%",
      width: "100%",
      alignItems: "center",
      wordBreak: "break-word"
    }}>
        <span onClick={() => onSeeDetails && onSeeDetails(eventMeeting)}>
          {event.title}
        </span>
        <>
          {eventMeeting.orderID && <IonButton className={"goToMapButton"} fill={"clear"} routerLink={`/leads/${eventMeeting.orderID}`}
        // style={
        //   eventMeeting.notes && eventMeeting.notes?.length > 0
        //     ? { right: "35%" }
        //     : { right: "15%" }
        // }
        >
              <IonIcon icon={eyeOutline} />
              {t("SeeDetails")}
            </IonButton>}
          {eventMeeting.map && !isFunction(eventMeeting.map) && <IonButton className={"goToMapButton"} fill={"clear"} target="_blank" href={eventMeeting.map}>
              <IonIcon icon={navigateOutline} />
              {t("GoToMaps")}
            </IonButton>}
          {eventMeeting.notes && eventMeeting.notes?.length > 0 && <IonButton className={"goToMapButton"} fill={"clear"} onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setNotesModal({
            bol: true,
            notes: eventMeeting.notes ? eventMeeting.notes : []
          });
        }}
        // style={{ right: "22%" }}
        >
              <IonIcon icon={chatbubbleEllipsesOutline} />
              {t("Note")}
            </IonButton>}
        </>
      </div>;
  };
  let formats = {
    timeGutterFormat: "HH:mm"
  };
  return <>
      {assignNewGBModal}
      <IonRow>{renderFilterPopover}</IonRow>
      {filter && <IonRow style={{
      padding: "0 .5rem"
    }}>
          <IonCol>
            <IonRow>
              <h1 style={{
            marginLeft: ".5rem"
          }}>{renderTitle}</h1>
            </IonRow>
          </IonCol>
          {gbArray.length > 1 && <IonCol>
              <IonButton className={"ion-float-right"} onClick={e => setFilterPopover({
          open: true,
          event: e.nativeEvent
        })} fill={"clear"} disabled={gbArray.length > 0 ? false : true}>
                <IonIcon src={chevronDown} />
                {t("CompareCalendars")}
              </IonButton>
            </IonCol>}
        </IonRow>}
      {gbArray && gbArray.length > 1 && <IonRow style={{
      padding: "0 .5rem"
    }}>{renderFilterChips}</IonRow>}
      {isLoading ? <IonLoading isOpen={isLoading} backdropDismiss translucent /> : <DnDCalendar components={{
      day: {
        event: CustomEvent // with the agenda view use a different component to render events
      }
    }} {...props} formats={formats} localizer={momentLocalizer(moment)} min={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8)} max={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 22)}
    // Click on event
    onSelectEvent={(event, mouseEvent) => {
      return props.popoverEnabled ? setEventActionPopover({
        open: true,
        event: mouseEvent.nativeEvent,
        calendarEvent: (event as Meeting)
      }) : undefined;
    }} events={renderEvent(gbs, drivingEvents, adminEvents, suggestedMeetings)} eventPropGetter={(e, startDate, endDate) => eventStyleGetter(e, moment(startDate).toDate(), moment(endDate).toDate(), gbs, orderDetail)} onSelectSlot={slotInfo => {
      onSelectNewEvent(slotInfo);
    }} onEventDrop={e => onEditEvent((e as SlotInfoEvent))} //disable for dashboard
    onEventResize={e => onEditEvent((e as SlotInfoEvent))} //disable for dashboard
    selectable />}
      {renderEventPopover}
      {renderChangeDateAlert}
      {renderAssignNotesModal}
    </>;
};
export default CalendarComponent;